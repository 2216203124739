
export default {
//adminPortalUrl: 'http://localhost:8081/',
//adminPortalUrl: 'https://App.zumanta.com/',
//adminPortalUrl: 'https://devportal.zumanta.com/',

//adminPortalUrl: 'https://dc2Portal.zumanta.com/',
    //For Live
    adminPortalUrl: 'https://portal.fraxioned.com/',
    //For QA
    //adminPortalUrl: 'https://QAAdmin.zumanta.com/',
    //forStage
    //adminPortalUrl: 'https://stageAdmin.zumanta.com/',
encryptkey:'4288943859999624',
encryptiv:'8935076607156689',
};