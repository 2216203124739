import api from './apiSetting';
import { saveAs } from 'file-saver';
class FrontEndService {
    async GetCompanyDetailsForWeb(companyUniqueName) {
        return await api.get(`ManageCompanyWebPage/GetCompanyDetailsForWeb/${companyUniqueName}`);
    }

    async GetCompanyDetailsByDomainOrName(companyDomainName, companyUniqueName, companyId, userId) {
           
        companyId =  localStorage.getItem('companyId');
        //userId = 123456
      
        return await api.get(`ManageCompanyWebPage/GetCompanyDetailsByDomainOrName/${companyDomainName}/${companyUniqueName}`);
    }
    async GetCompanyDetailsByDomainName(companyDomainName) {
        return await api.post(`ManageCompanyWebPage/GetCompanyDetailsByDomainName`, companyDomainName);
    }
    async GetCompanyIdsandUserIdByDomainName(companyDomainName) {
        return await api.get(`ManageCompanyWebPage/GetCompanyIdsandUserIdByDomainName/${companyDomainName}`, companyDomainName);
    }
    async GetDynamicPageContent(pageURL, companyId = null) {
        companyId = (companyId == null) ? localStorage.getItem('companyId') : companyId;
        return await api.get(`DynamicPages/GetDynamicPageContent/${pageURL}/${companyId}`);
    }
    async savecontactus(contactusform) {
        return api.post(`ContactUs`, contactusform)
    }
    async UnSubscribed(Email, companyId = null) {
        companyId = (companyId == null) ? localStorage.getItem('companyId') : companyId;
        return await api.get(`ManageSubscribers/UnsubscribeNewletter/${Email}/${companyId}`)
    }
    async AddSubscriber(manageSubscribersModel) {
        return await api.post(`ManageSubscribers/AddEditSubscribers`, manageSubscribersModel);

    }

    async GetRefreshToken(CompanyId) {
        return await api.get(`ManageSubscribers/GetRefreshToken/${CompanyId}`);
    }

    async UpdateTokens(model) {
        return await api.post(`ManageSubscribers/UpdateToken`, model);
    }

    async getCompanyData(companyId) {
        return await api.get(`ManageCompanyWebPage/GetCompanyData/${companyId}`);
    }
    async getnewslist(id, companyid, pagesize, LanguageId) {
           
        return api.get(`ContactUs/getnewslist/${id}/${companyid}/${pagesize}/${LanguageId}`)
    }
    async getnewsdetailbyid(id) {
           
        return api.get(`ContactUs/getnewsbyid/${id}`)
    }
    async GetSocialLinkList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId, status) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") {
            searchFields = "00Test";

            return await api.get(`SocialMediaLink/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}/${status}`);
        }
    }
    getAddressDetail(companyId, userId) {
        return api.get(`ContactUs/GetContactUsData/${companyId}/${userId}`);
    }
    async getTestimonailList(companyId, userId, LanguageId) {
        return await api.get(`ManageTestimonial/getTestimonailList/${companyId}/${userId}/${LanguageId}`);
    }
    async AddCompanyUsers(signUpModel) {
        return await api.post(`CompanyUsers/AddCompanyUsers`, signUpModel);
    }
    async AddEditContactUser(userModel) {
        return await api.post(`ManageContacts/AddEditContactUser`, userModel);
    }
    async getAccountTypeDetails(companyId) {
        return await api.get(`CompanyUsers/GetAccountTypeList/${companyId}`);
    }
    //async LogIn(User) {
    //    return await api.post('Account/CompanyUserLogin', User);
    //}
    async LogIn(User) {
        debugger
        return await api.post('Cart/UserLogin', User);
    }

    async getCurrentUserDetail(userId, actionType) {
        let optionaParamersType = "00Test";
        await api.get(`UserProfile/GetUserInfo/${userId}/${actionType}/${optionaParamersType}`).then(response => (
            localStorage.setItem('userinfo', JSON.stringify(response.data))
        ))
    }
    forgotpassword(forgotmodel) {
        return api.post('Account/ForgotPasswordForCompWeb', forgotmodel);
    }
    GetAssetList(SearchModel) {
        return api.post(`ManageAsset/GetAssetList`, SearchModel);
    }
    //GetAssetList_Home
    GetAssetListHomePage(SearchModel) {
        return api.post(`ManageAsset/GetAssetList_Home`, SearchModel);
    }
    GetAssetCategoriesList(categorySearchModel) {
        return api.post(`ManageAsset/GetAssetCategoriesList`, categorySearchModel);
    }
    GetAssetFeaturesList(model) {
        return api.post(`ManageAsset/GetAssetFeaturesList`, model);
    }
    async GetAssetLocationStatesList(userId, CompanyId) {
        return api.get(`ManageAsset/GetAssetLocationStatesList/${userId}/${CompanyId}`);
    }
    GetSavedAssetSearchFilters(Id, companyId, userId, ipAddress, sortColumn, sortDir, isApplied) {
        return api.get(`ManageAsset/GetSavedAssetSearchFilters/${Id}/${companyId}/${userId}/${ipAddress}/${sortColumn}/${sortDir}/${isApplied}`)
    }
    async DeleteAssetFilter(id) {
        return api.delete(`ManageAsset/DeleteAssetFilter/${id}`)
    }
    async resetPassword(setPassword) {
        return await api.post(`Account/ResetPassword`, setPassword);
    }
    GetAssetDetailsForVactionSwapMarketplace(assetId, userId, shareid) {
        return api.get(`ManageAsset/GetAssetDetailsForVactionSwapMarketplace/${assetId}/${userId}/${shareid}`);
    }
    async AddUpdateAssetWishList(WishListModel) {
        return api.post('ManageAsset/AddUpdateAssetWishList', WishListModel);
    }
    async GetWishListAssets(SearchModel) {
        return api.post('ManageAsset/GetWishListAssets', SearchModel);
    }
    async removeAssetsFromWishList(id) {
           
        return api.get(`ManageAsset/DeleteAssetfromWishList/${id}`);
    }
    async AddUpdateReview(reviewmodel) {
           
        return api.post('ManageAsset/AddReview', reviewmodel);
    }
    async getAssetAccountTypeList() {
        return await api.get(`ManageAsset/GetAssetAccountTypeList`);
    }
    async AddInterestCompanyProperty(interestCompProperty) {
        return await api.post(`ManageAsset/AddInterestCompanyProperty`, interestCompProperty);
    }
    async AddContactInformationCompanyProperty(ContactCompProperty) {
        return await api.post(`ManageAsset/AddContactInformationCompanyProperty`, ContactCompProperty);
    }
    async AddPaymentDetailsProperty(PayementDetails) {
        return await api.post(`ManageAsset/AddPaymentDetails`, PayementDetails);
    }
    async AddMinimumAmountPaymentDetailsProperty(PayementDetails) {
        return await api.post(`ManageAsset/AddMinimumPaymentDetails`, PayementDetails);
    }
    async CheckRentAvailability(model) {
        return await api.post(`ManageAsset/CheckRentAvailability`, model);
    }
    async AddPaymentDetailsRentProperty(PayementDetails) {

        return await api.post(`ManageAsset/AddPaymentRentDetails`, PayementDetails);
    }
    async getUserDetail(id, optional) {
        return await api.get(`UserProfile/GetUserDetailById/${id}/${optional}`);
    }
    async updateUserProfile(userProfile) {
        return await api.post(`UserProfile/CompanyWebUpdateUser`, userProfile);
    }
    async AddScheduleMeeting(interestCompProperty) {
        return await api.post(`Meeting/AddEditMeetingDetails`, interestCompProperty);
    }
    async GetPaymentListAssets(SearchModel) {
        return api.post('ManageAsset/GetPaymentListAssets', SearchModel);
    }
    async removePaymentHistoryfromPaymentList(id) {
        return api.get(`ManageAsset/DeletePaymentHistoryfromPaymentList/${id}`);
    }
    async gettoken(email) {
        return await api.get(`UserProfile/gettoken/${email}`);
    }
    async setPassword(setPassword) {
        return await api.post(`Account/ResetPassword`, setPassword);
    }
    async SaveProfileFile(formData) {
        return await api.post(`UserProfile/SaveProfileFile`, formData, {
            "Content-Type": "multipart/form-data"
        });
    }
    async getManageArticle(search, userid, companyid, pagesize) {
        return await api.get(`CompanyDashboard/GetArticleList/${search}/${userid}/${companyid}/${pagesize}`);
    }
    async getArticleDataById(id, userID) {
        return await api.get(`CompanyDashboard/GetArticleDetail/${id}/${userID}`);
    }
    async GetAreaofInterestList(SearchModel) {
        return await api.post('AreaofInterest/GetAreaofInterestList', SearchModel);
    }
    async countryList(masterNames, masterKeyText, userId) {
        return await api.get(`userList/GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);
    }
    async getStateList() {
        return await api.get('UserList/GetStateList');
    }
    async AOFAddEdit(AOIModel) {
        return await api.post(`AreaofInterest/AddEditAreaofInterest`, AOIModel);
    }
    async DeleteAOI(id) {
        return api.get(`AreaofInterest/DeleteAOI/${id}`);
    }
    async GetEditAOI(id) {

        return await api.get(`AreaofInterest/EditAOIbyId/${id}`);
    }
    async getManageCourse(search, userid, companyid, pagesize) {
        return await api.get(`CompanyDashboard/GetCourseList/${search}/${userid}/${companyid}/${pagesize}`);
    }
    async getCourseDetail(id, userID) {
        return await api.get(`CompanyDashboard/GetCourseDetail/${id}/${userID}`);
    }
    async getMonthlyContent(search, userid, companyid, pagesize) {
        return await api.get(`CompanyDashboard/GetMonthlyContentList/${search}/${userid}/${companyid}/${pagesize}`);
    }
    async getMonthlyContentDetail(id, userID) {
        return await api.get(`CompanyDashboard/GetMonthlyContentDetail/${id}/${userID}`);
    }
    async GetAssetMembershipListing(sortDir, pageIndex, pageSize, userId, companyId) {
        return await api.get(`ManageAsset/GetMembershipList/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}`);
    }
    async getMembershipDataById(id, userId) {
        return await api.get(`ManageAsset/GetMembershipById/${id}/${userId}`);
    }
    async AddMembershipUser(membershipModel) {
        return await api.post(`CompanyUsers/AddCompAmbasdhoorUsers`, membershipModel);
    }
    async getMembershipPaymentHistory(search, userid, sortby, orderby, pageindex, pagesize) {
        return await api.get(`MemberShipsDetail/getmembershipdetail/${search}/${userid}/${sortby}/${orderby}/${pageindex}/${pagesize}`);
    }
    async getMembershipDetail(userid) {
        return await api.get(`MemberShipsDetail/GetMembershipdetail/${userid}`);
    }
    async PaySubscriptionDuePayment(makepayment) {
        return await api.post(`MemberShipsDetail/MakeMebershipPayment`, makepayment);
    }
    
    async getApprovedReviewsList(AssetId, companyId, sortby, orderby) {
        var userId = null;
        return await api.get(`ManageAsset/getapprovedreview/${AssetId}/${companyId}/${sortby}/${orderby}/${userId}`);
    }


    
    async GetSliderAssetList(SearchModel, assetCategoryId, locationId) {
        if (assetCategoryId == '')
            assetCategoryId = '00000000-0000-0000-0000-000000000000';
        if (locationId == '')
            locationId = '00000000-0000-0000-0000-000000000000';
        return await api.post(`ManageAsset/GetSliderAssetList/${assetCategoryId}/${locationId}`, SearchModel);
    }
    async GetStunningPropertyList(SearchModel, assetCategoryId, locationId) {
        if (assetCategoryId == '')
            assetCategoryId = '00000000-0000-0000-0000-000000000000';
        if (locationId == '')
            locationId = '00000000-0000-0000-0000-000000000000';
        return await api.post(`ManageAsset/GetStunningPropertyList/${assetCategoryId}/${locationId}`, SearchModel);
    }
    async GetReviewsListing(userId, companyId, flag, pageIndex, pageSize) {
        return await api.get(`ManageAsset/GetReviewsListing/${userId}/${companyId}/${flag}/${pageIndex}/${pageSize}`);
    }
    async GetFrequentlyAskedQuestionsList(SearchModel) {
        return await api.post(`FrequentlyAskedQuestions/GetFrequentlyAskedQuestionsList`, SearchModel);
    }
    async downloadInvoice(companyid, userid, companylogo) {
        return await api.get(`manageCompany/DownloadUserInvoicePdf/${companyid}/${userid}/${companylogo}`, { responseType: 'blob' });
    }
    async downloadExcel(records, filename, firstListHeader, secondListHeader) {
        return await api.post(`Common/ExportExcelForMemberShip/${filename}/${firstListHeader}/${secondListHeader}`, records, { responseType: 'blob' });
    }
    async ExportData(records, type, filename, pageLength = "") {
        if (type == 'Excel') {
            api.post(`Common/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).then(response => {
                saveAs(response.data, filename + ".xlsx")
            })
        }
        else if (type == 'CSV') {
            api.post(`Common/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).then(response => {
                saveAs(response.data, filename + ".csv")

            })
        }
        else if (type == 'PDF') {
            api.post(`Common/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).then(response => {
                saveAs(response.data, filename + ".pdf")
            })
        }
    }
    async GetContactUserList(searchModel) {
        return await api.post(`ManageContacts/GetContactUserList`, searchModel);
    }
    async GetContactUserById(id) {
        return await api.get(`ManageContacts/GetContactUserById/${id}`);
    }
    async DeleteContactUserById(id) {
        let userId = localStorage.getItem('userId')
        return await api.get(`ManageContacts/DeleteContactUserById/${id}/${userId}`);
    }
    async SendShareLinkEmail(sendEmailModel) {
        return await api.post(`Newsletter/SendShareLinkEmail`, sendEmailModel);
    }

    async ImportContatcFromExcel(importModel) {
        return await api.post(`ManageContacts/SaveBulkUpload`, importModel);
    }
    async SendMessage(objCommunication) {
        return await api.post(`Communication/SendMessage`, objCommunication, {
            "Content-Type": "multipart/form-data"
        });
    }

    async GetMessagesList(searchFields, sortColumn, sortDir, pageIndex, pageSize, companyId, userId, isListMode) {
        return await api.get(`Communication/GetUserCommunicationList/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${companyId}/${userId}/${isListMode}`)
    }

    async GetUsersByUserType(UserId, CompanyId, UserTypeId) {
        return await api.get(`Communication/GetUsersByUserType/${UserId}/${CompanyId}/${UserTypeId}`)
    }
    async GetUsertypeId(refcode, UserId, CompanyId) {
           
        return await api.get(`Communication/GetUserTypes/${refcode}/${UserId}/${CompanyId}`)
    }
    async GetUserCommunicationDetailById(Id, UserId) {

        return await api.get(`Communication/GetUserCommunicationDetailById/${Id}/${UserId}`)
    }
    async GetUserCommunicationThreadsById(Id, UserId) {
           
        return await api.get(`Communication/GetUserCommunicationThreadsById/${Id}/${UserId}`)
    }
    async DeleteMessageUserWise(ID, UserId, CompanyId) {
           
        return await api.get(`Communication/DeleteMessageUserWise/${ID}/${UserId}/${CompanyId}`);
    }
    async getTopRatedProperty(id, companyId, pagesize, page, sortBy, orderBy) {
        var userId = null;
        return await api.get(`ManageAsset/GetAssetReview/${id}/${companyId}/${pagesize}/${page}/${sortBy}/${orderBy}/${userId}`);
    }
    async getTopLocations(id, companyId, pagesize, page, sortBy, orderBy) {
           
        return await api.get(`ManageAsset/GetLocationWithCount/${id}/${companyId}/${pagesize}/${page}/${sortBy}/${orderBy}`);
    }
    async DownloadAttachmentFile(docname) {
           
        return await api.get(`Communication/DownloadDoc/${docname}`, { responseType: 'blob' })
    }
    async GetSideMenuModuleForWeb(userid) {
        return await api.get(`ManageCompanyWebPage/GetSideMenuModuleForWeb/${userid}`)
    }
    async SendEmailSwapMarket(emailModel) {
        return await api.post(`VacationSwapMarketplace/SaveEmailVacationSwapMarket`, emailModel);
    }
    async GetVacationSwapMarketplace(searchModel) {
        return api.post('VacationSwapMarketplace/GetVacationSwapMarketplace', searchModel);
    }
    async getVacationSwapMarketplaceEmailListing(searchModel) {
        return await api.post(`VacationSwapMarketplace/GetEmailVacationSwapMarketList`, searchModel);
    }
    async GetTestimonialList(searchFields, status, sortColumn, sortDir, page, pageSize, userId, companyId, CurrentSelectLanguage) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") { searchFields = "00Test"; }
        return await api.get(`ManageTestimonial/GetTestimonialLists/${searchFields}/${status}/${sortColumn}/${sortDir}/${page}/${pageSize}/${userId}/${companyId}/${CurrentSelectLanguage}`);

    }
    GetAssetDetail(assetId, userId, ipAddress) {
        return api.get(`ManageAsset/GetAssetDetail/${assetId}/${userId}/${ipAddress}`);
    }
    async GetAssetDocumentDetail(assetId, userId, companyId) {
        return await api.get(`ManageAsset/GetAssetDocumentDetails/${assetId}/${userId}/${companyId}`);
    }
    async GetAssetPolicyDetail(assetId, userId, companyId) {
           
        return await api.get(`ManageAsset/GetAssetCancellationPolicy/${assetId}/${companyId}/${userId}`);
    }
    async UploadAssetDocumentDetail(formData) {
        return await api.post(`ManageAsset/SaveAssetDocumentDetails`, formData, {
            "Content-Type": "multipart/form-data"
        });
    }
    async GetAssetBuyDocumentList(searchModel) {
        return api.post('ManageAsset/AssetUploadedDocumentList', searchModel);
    }
    async DownloadESignDocument(fileName, IsSigned) {
        return api.get(`ManageESignedDocument/DownloadESignDocument/${fileName}/${IsSigned}`);
    }
    async GetESignDocumentList(searchModel) {
        return api.post('ManageESignedDocument/ESignDocumentList', searchModel);
    }
    async GetAdvanceBookingDetail(assetId, userId) {
        return api.get(`ManageAsset/GetAdvanceBookingDetail/${assetId}/${userId}`);
    }
    async DownloadDocument(fileName) {
        return await api.get(`ManageAsset/DownloadDocumentFile/${fileName}`);
    }

    async DeleteAssetDocument(assetId, documentId) {
        return await api.get(`ManageAsset/DeleteAssetDocument/${assetId}/${documentId}`);
    }
    async CancelAssetBooking(model) {
        return await api.post(`ManageAsset/CancelAssetBooking`, model);
    }
    async GetCancelAssetPolicyDetials(assetId, companyId, userId) {
        return await api.get(`ManageAsset/CancellationPolicyCharges/${assetId}/${companyId}/${userId}`);
    }
    async getVacationSwapMarketplaceSmsListing(searchModel) {
        return await api.post(`VacationSwapMarketplace/GetSmsVacationSwapMarketList`, searchModel);
    }
    async SendSmsSwapMarket(SmsModel) {
        return await api.post(`VacationSwapMarketplace/SendSMS`, SmsModel);
    }
    async GetAssetViewList(SearchModel) {
        return await api.post('ManageAssetView/GetAssetViewList', SearchModel);
    }
    async companysubscribeNewsletter(formdata) {
           
        return await api.post(`CompanyDashboard/subscribeNewsletter`, formdata);
    }
    async getCommissionReport(searchModel) {
           
        return await api.post(`CompanyDashboard/CommisionListing`, searchModel);
    }
    async getPaymentHistorybyId(userid, Id) {
        return await api.get(`MemberShipsDetail/GetmembershipPaymentbyId/${userid}/${Id}`);
    }
    async SingledownloadInvoice(Id, companyid, userid, companylogo) {
        return await api.get(`manageCompany/DownloadSingleInvoicePdf/${Id}/${companyid}/${userid}/${companylogo}`, { responseType: 'blob' });
    }
    async GetSocialMediaProfileDetails(userId, companyId) {
        return await api.get(`UserProfile/GetSocialMediaProfileById/${userId}/${companyId}`);
    }
    async SaveSocialMediaProfileDetails(socialMediaModel) {
        return await api.post(`UserProfile/SaveSocialMediaProfileDetails`, socialMediaModel);
    }
    async DeleteAccount(userId, companyId) {
        return api.get(`Account/DeleteAccount/${userId}/${companyId}`);
    }
    async deletePropertyContactInfoById(Id) {
        return await api.get(`ManageAsset/DeletePropertyContactInformationById/${Id}`)
    }
    async GetPropertyScheduleMeetingList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") { searchFields = "00Test"; }
        return await api.get(`ManageAsset/GetPropertyScheduleMeetingList/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}`);
    }
    async deletePropertyReviewById(Id) {

        return await api.get(`ManageAsset/DeletePropertyReviewById/${Id}`)
    }
    async GetPropertyReviewList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") { searchFields = "00Test"; }
        return await api.get(`ManageAsset/GetPropertyReviewList/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}`);
    }
    async IsApproveStatus(id, status) {
        return await api.get(`ManageAsset/IsApproveStatusById/${id}/${status}`)
    }
    async Deletemonthcontent(id) {
           
        return await api.get(`MonthlyContent/DeleteMonthlyContentById/${id}`);
    }
    async getmonthlycontentDatabyId(id) {
        return await api.get(`MonthlyContent/GetMonthlyContentById/${id}`);
    }
    async AddupdatemonthlycontentFrontend(monthlyContent) {
        return await api.post(`MonthlyContent/AddManageMonthlyContentFromCompany`, monthlyContent, {
            "Content-Type": "multipart/form-data"
        });
    }
    async getMonthlyContentAmbassidorList(searchModel) {
        return await api.post(`CompanyDashboard/GetMonthlyContentCompanyList`, searchModel);
    }
    async movetoMarketPlace(AspNetId, AssetID, CompanyId, MovetoMarketPlaceVal, ShareId) {
        return await api.get(`ManageAsset/MovetoMarketPlace/${AspNetId}/${AssetID}/${CompanyId}/${MovetoMarketPlaceVal}/${ShareId}`)
    }
    async GetMenuContent(pageURL, currentSelectLanguage, companyId = null) {
        companyId = (companyId == null) ? localStorage.getItem('companyId') : companyId;
        return await api.get(`DynamicCompanyMenu/GetMenuContent/${pageURL}/${currentSelectLanguage}/${companyId}`);
    }

    async GetHeaderFooterData(userId, companyId, LanguageId) {
        companyId =  localStorage.getItem('companyId');
        return await api.get(`DynamicCompanyMenu/GetCompanyMenuByHeaderFooter/${userId}/${companyId}`)
    }
    async SharetoallMontely(userId, sharetoall) {
        return await api.get(`MonthlyContent/ShareToAllMontlyContent/${userId}/${sharetoall}`)
    }
    async getSwapPropertyRequestRecevicedlist(searchModel) {
        return await api.post(`VacationSwapMarketplace/GetAssetRequestRecevicedList`, searchModel);
    }
    async GetMembershipDetailsById(id) {
        return await api.get(`CompanyUsers/GetMembershipDetailsById/${id}`);
    }
    async PayMembership(MemebershipModel) {
        return await api.post(`CompanyUsers/PayMembership`, MemebershipModel);
    }
    async updateCurrentUserDetail(userId, actionType) {
        let optionaParamersType = "00Test";
        return await api.get(`UserProfile/GetUserInfo/${userId}/${actionType}/${optionaParamersType}`)
    }
    async SellOwnedAsset(SellModel) {
        return api.post('ManageAsset/SellOwnedAsset', SellModel);
    }
    async countryList(masterNames, masterKeyText, userId) {
        return await api.get(`userList/GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);

    }
    async Addclientfeedback(model) {
        return await api.post(`ManageAsset/AddMeetingReview`, model);

    }
    async GetAllOwnedAssetByUser(userId, companyId) {
        return await api.get(`ManageAsset/GetAllOwnedAssetByUser/${userId}/${companyId}`);
    }
    async SubmitHolidaySlotsInDb(addPerferences) {
        return await api.post(`ManageAsset/SubmitHolidaySlotsInDb`, addPerferences);
    }
    async GetAssetHolidayPreferenceDetail(assetId, userId) {
        return await api.get(`ManageAsset/GetAssetHolidayPreferenceDetail/${userId}/${assetId}`);
    }
    async getBloglist(search, categoryid, userId, companyId, pagesize, LanguageId, PageNo) {
        if (search == '') { search = '00test' }
        return await api.get(`Blog/GetBlogLists/${search}/${categoryid}/${userId}/${companyId}/${pagesize}/${LanguageId}/${PageNo}`);
    }
    async getBlogDatabyId(id, userId, companyId) {
        companyId =  localStorage.getItem('companyId');
        return await api.get(`Blog/GetBlogbyid/${id}/${userId}/${companyId}`);
    }
    async GetBlogCategoryList(userid, LanguageId) {
        return await api.get(`Blog/blogcategorylist/${userid}/${LanguageId}`)
    }
    async UnSubscribe(Email, companyId) {
        return await api.get(`ManageSubscribers/Unsubscribe/${Email}/${companyId}`)
    }
    async downloadAssetInvoice(companyid, userid, AssetId, InvoiceNumber) {
        return await api.get(`AssetInvoice/AssetInvoicePdf/${companyid}/${userid}/${AssetId}/${InvoiceNumber}`,
            { responseType: 'blob' });
    }
    async getAssetBuyDetail(companyid, userid, AssetId) {
        return await api.get(`AssetInvoice/GetAssetBuyDetail/${companyid}/${userid}/${AssetId}`);
    }

    async Getlegalterms(asssetId) {
           
        return await api.get(`ManageAsset/Getlegalterms/${asssetId}`);
    }
    async getAssetRentDetail(companyid, userid, AssetId) {
        return await api.get(`AssetInvoice/GetAssetRentDetail/${companyid}/${userid}/${AssetId}`);
    }
    async downloadAssetRentInvoice(companyid, userid, AssetId) {
        return await api.get(`AssetInvoice/AssetRentInvoicePdf/${companyid}/${userid}/${AssetId}`,
            { responseType: 'blob' });
    }
    async AddPaymentDetailsWireProperty(model) {
        return await api.post(`ManageAsset/AddPaymentDetailsWithWire`, model);
    }
    async GetdynamicMetadata(model) {
        return await api.post(`DynamicCompanyMenu/GetDynamicMetadata`, model);
    }

    async QuickSearchSetting(CompanyId) {

        return await api.get(`ManageAsset/GetQuickSearchSettingsList/${CompanyId}`);
    }
    async Addassetsubmitrequest(assetid, aspNetId, companyId, requestshareno) {
        return await api.post(`ManageAsset/Addassetsubmitrequest/${assetid}/${aspNetId}/${companyId}/${requestshareno}`);
    }
    async LoginHistoryForUsers(model) {
        return await api.post('Account/LoginHostoryForUsers', model);
    }
    async LogoutHistory(userId, IPAddress) {
        return await api.get(`account/LogOutHostoryForUsers/${userId}/${IPAddress}`)
    }
    NewestAssetList(SearchModel) {
           
        return api.post(`ManageAsset/GetNewestAssetListing`, SearchModel);
    }
    async GetLanguageList(masterNames, masterKeyText, userId) {
        return await api.get(`userList/GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);
    }
    async getHomepageData(companyId, UserId) {
        return await api.get(`AllService/GetAllServices/${companyId}/${UserId}`)
    }

    async getAllWorkTypes(companyId) {
           
        return await api.get(`AllService/GetAllWorkTypes/${companyId}`)
    }

    async getFeaturedServices(companyId, UserId) {
        return await api.get(`Cart/GetFeaturedService/${companyId}/${UserId}`)
    }

    async getServiceByWorkType(companyId, WoId,UserId,currentCartId) {
           
        return await api.get(`AllService/GetServiceByWorkType/${companyId}/${WoId}/${UserId}/${currentCartId}`)
    }
    async updateQuantitytoCartItem(cartModel) {
            
        return await api.post(`Cart/UpdateQuantitytoCartItem`, cartModel);
    }

    async AddtoCartItem(cartModel) {
        return await api.post(`Cart/AddtoCartItem`, cartModel);
    }

    async getCartItemsByCompanyId(companyId,userId) {
        debugger;
        return await api.get(`Cart/GetCartItemsByCompanyId/${companyId}/${userId}`)
        
    }
    async AddPayment(PayNowModel,gatewayType) {
           
        return await api.post(`ManageAsset/AddPayment`, PayNowModel);
    }
    async SignUpUser(model) {
           
        return await api.post(`Cart/RegisterCustomer`, model);
    }

    async getCountriesList() {
        return await api.get(`AllService/GetCountriesList`)
    }

    async getStateListByCountryCode(countryCode) {
        return await api.get(`AllService/GetStateListByCountryCode/${countryCode}`)
    }
    async GetStateListByCountryId(id) {
        return await api.get(`AllService/GetStateListByCountryId/${id}`)
    }
    async getAddressDetails(companyId, userId) {
           
        return await api.get(`Cart/GetAddressDetails/${companyId}/${userId}`)

    }

    async getuserInfo(companyId, userId) {
           
        return await api.get(`Cart/GeLoginuserInfo/${companyId}/${userId}`)
    }

    async GetMostBookedServices(companyId) {
           
        return await api.get(`Cart/GetMostBookedServices/${companyId}`)
    }

    async getCustomerAddresses(userId) {
        return await api.get(`Cart/GetCustomerAddresses/${userId}`)
    }

    async AddCartItemsforLoggedinUser(model) {
           
        return await api.post(`Cart/AddCartItemsforLoggedinUser`, model);
    }
    async VarifyCustommerLocation(Latitude, Langitude, CompanyId) {
        debugger;
        return await api.get(`Cart/VarifyCustommerLocation/${Latitude}/${Langitude}/${CompanyId}`)
    }


    async GetSF_ServiceById(companyId, ServiceId, UserId) {

        return await api.get(`AllService/GetSF_ServiceById/${companyId}/${ServiceId}/${UserId}`)
    }

    async GetSF_FeaturedServiceById(companyId, ServiceId, UserId) {

        return await api.get(`AllService/GetSFFeture_ServiceById/${companyId}/${ServiceId}/${UserId}`)
    }




    async GetIPAddress() {
        const response = await fetch('https://api.ipify.org?format=json');
        const data_res = await response.json();
        if (data_res != null) {
            return await data_res.ip;
        }
        else {
            return await "::1";
        }

    }
    async getIpGeoDetails(ip) {
        debugger
    try {
        // Fetch geolocation details using ipapi.co
        const response = await fetch(`https://ipapi.co/${ip}/json/`);
        const data_res = await response.json();
        return data_res;
    } catch (error) {
        console.error('Error fetching IP geolocation:', error);
        throw error;
    }
}
    GetAssetListRentBooking(SearchModel) {
        return api.post(`ManageAsset/GetAssetList_RentBooking`, SearchModel);
    }
    async submitAssetInquiry(model) {
        return await api.post(`Inquiry/AddEditInquiry`, model);
    }
    async AddCurrentCartItem(cartModel) {
        return await api.post(`Cart/AddCurrentCartItem`, cartModel);
    }
    async GetCurrentCartItems(companyId, userId, currentcartId, isLoginUser) {
        return await api.get(`Cart/GetCurrentCartItems/${companyId}/${userId}/${currentcartId}/${isLoginUser}`)
    }
    async UpdateQuantitytoCart(cartModel) {
        return await api.post(`Cart/UpdateQuantitytoCart`, cartModel);
    }
    async GetSlotTimeByDate(companyId,weekdayName) {
        return await api.get(`Cart/GetSlotTimeByDate/${companyId}/${weekdayName}`)
    }
}
export default new FrontEndService();