<template>
    <SignUpComponent v-if="isLoadComplete"
                     :companyName="companyName"
                     :schema="schema"
                     :signUpData="signUpData"
                     :listAccountType="listAccountType"
                     @submitClicked="onSubmit"
                     @switchToSignin="switchToSignin"
                     @cancel="cancel"></SignUpComponent>

    <Loading v-model:active="isLoading">
    </Loading>
</template>

<script>

    import frontEndService from '@/Services/frontEndService.js'
    import { Form, Field } from 'vee-validate';
    import InputMask from '@/views/shared/inputmask/inputmasktemplate.vue';
    import * as Yup from 'yup';

    export default {
        name: 'Sign Up',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.SignUpComponent = require('@/layout/Themes/DarkTheme/Views/SignUp/signUp.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.SignUpComponent = require('@/layout/Themes/CleanTheme/Views/SignUp/signUp.vue').default;
            }
            else if (this.themeName == 'Classic') {

                this.$options.components.SignUpComponent = require('@/layout/Themes/ClassicTheme/Views/SignUp/signUp.vue').default;
            }
            else if (this.themeName == 'Modern') {

                this.$options.components.SignUpComponent = require('@/layout/Themes/ModernTheme/Views/SignUp/signUp.vue').default;
            }
            else {
                this.$options.components.SignUpComponent = require('@/layout/Themes/DefaultTheme/Views/SignUp/signUp.vue').default;
            }
        },
        components: {
            SignUpComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/SignUp/signUp.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/SignUp/signUp.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/SignUp/signUp.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/SignUp/signUp.vue');
                }
                else {

                    import('@/layout/Themes/DefaultTheme/Views/SignUp/signUp.vue');
                }
            },
            Form,
            Field,
            InputMask,
        },
        data() {
            let themeName = localStorage.getItem('theme');
            const schema = Yup.object().shape({
                firstname: Yup.string().required('First Name is required'),
                lastname: Yup.string().required('Last Name is required'),
                /*username: Yup.string().required('Username is required').min(4, 'Username must be atleast 4 characters.').max(30, 'Username should be less than 30 characters.').matches('^(?![])(?!.*[]{2})[a-zA-Z0-9\.\_\-]+$', 'Username is invalid'),*/
                username: Yup.string().required('Username is required').min(4, 'Username must be atleast 4 characters.').max(30, 'Username should be less than 30 characters.'),
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
                phone: Yup.string().required('Phone is required'),
                password: Yup.string().required('Password is required').matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$', 'Password should be combination of lower case, upper case, numeric, special character, and must contain 8 characters'),
                confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Confirm Password does not match').required('Confirm Password is required'),
                accountType: Yup.string().required('Account Type is required')
            });

            return {
                companyLogo: '',
                signUpData: {
                    firstname: '',
                    lastname: '',
                    username: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmPassword: '',
                    accountType: '',
                    isTermAndCondition: false,
                    userId: '',
                    companyId: localStorage.getItem('comp-companyId'),

                },
                themeName,
                showconformPassword: false,
                showPassword: false,
                Heardername: '',
                registertionlinkpopup: false,
                registraionLink: '',
                Emailconformed: false,
                modelpopup: false,
                schema,
                modulePermission: null,
                listAccountType: [],
                compcompanyId: localStorage.getItem('comp-companyId'),
                isLoading: false,
                isLoadComplete: false,
                isOpenModal: false
            }
        },
        emits: ["cancelClicked"],
        created() {
            this.GetAccountTypeDetail();
        },
        mounted() {
          this.isLoading = false;
        },
        methods: {
            async onSubmit() {

                if (!this.signUpData.isTermAndCondition) {
                    this.error("Term and Condition is required.");
                    return
                }
                this.isLoading = true;
                if (this.signUpData != null) {
                    var userModel = {
                        FirstName: this.signUpData.firstname,
                        LastName: this.signUpData.lastname,
                        UserName: this.signUpData.username,
                        Email: this.signUpData.email,
                        PhoneNumber: this.signUpData.phone,
                        Password: this.signUpData.password,
                        isTermAndCondition: this.signUpData.isTermAndCondition,
                        UserTypeId: this.signUpData.accountType,
                        companyId: this.compcompanyId,
                        CreatedBy: localStorage.getItem('comp-userId'),
                        companyName: localStorage.getItem("companyName"),
                        logoLink: localStorage.getItem("companyLogo")
                    }
                    await frontEndService.AddCompanyUsers(userModel).then(
                        res => {
                            if (res.data.statusCode == 200) {
                                this.success(res.data.responseMessage);
                                 this.$parent.closeSignUpdmodel();
                                this.isLoading = false;                               
                               
                            }
                            else if (res.data.statusCode == 500) {
                                this.error(res.data.responseMessage);
                                this.isLoading = false;
                            }
                            else {
                                this.error("Something went wrong. Please contact site administrator.");
                                this.isLoading = false;
                            }
                        }

                    );
                }
                else {
                    this.$toast.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                }
            },

            async GetAccountTypeDetail() {
                await frontEndService.getAccountTypeDetails(this.compcompanyId).then(response => {
                    this.listAccountType = response.data
                    this.isLoadComplete = true;
                });
            },

            cancel() {
                this.$emit("cancelClicked");
            },
            switchToSignin() {
                this.$parent.isopenSignUpModal = false;
                this.$parent.loginmodelopen = true;
            }
        }
    }

</script>