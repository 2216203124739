<template>
      <footer class="footer-area" v-if="ShowFooter" :style="{ background: footerBackgroundColor }">
            <div class="container" >
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget">
                            <div class="logo">
                                <a>
                                    <!--<img v-if="footerLogo==''" src="../../../../../assets/img/logo-2.png" class="main-logo" alt="logo">-->

                                    <img v-if="footerLogo!==''" :src="footerLogo" class="main-logo" alt="logo">
                                </a>
                            </div>
                            <p>{{footerDescription}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget pl-40" v-if="footerServiceSection">
                            <h3>{{ footerServiceSection }}</h3>
                            <ul>
                                <li v-for="(serviceName, index) in splitServiceNames" :key="index">
                                    <i class="icofont-simple-right"></i>
                                    <a :href="splitServiceNamesURl[index]">{{ serviceName }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget pl-40" v-if="footerQuickLinks">
                            <h3>{{footerQuickLinks}}</h3>
                            <ul>
                                <li v-for="(linkName, index) in splitLinkNames" :key="index">
                                    <i class="icofont-simple-right"></i>
                                    <a :href="splitLinkUrls[index]">{{ linkName }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6" v-if="Company_SuportTittles">
                        <div class="footer-widget pl-40">
                            <h3>{{Company_SuportTittles}}</h3>
                            <ul>
                                <li v-for="(linkName, index) in splitCompany_SuportTittlesNames" :key="index">
                                    <i class="icofont-simple-right"></i>
                                    <a :href="splitLinkCompanyUrls[index]">{{ linkName }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget">
                            <h3>Find Us</h3>
                            <div class="footer-social">
                                <ul>
                                    <li class="d-inline-block" v-if="facebook">
                                        <a :href="splitLinkUrlsofFacebook"><i class="icofont-facebook"></i></a>
                                    </li>
                                    <li class="d-inline-block" v-if="twitter">
                                        <a :href="splitLinkUrlsoftwitter"><i class="icofont-twitter"></i></a>
                                    </li>
                                    <li class="d-inline-block" v-if="linkedin">
                                        <a :href="splitLinkUrlsoflinkedin"><i class="icofont-linkedin"></i></a>
                                    </li>
                                    <li class="d-inline-block" v-if="instagram">
                                        <a :href="splitLinkUrlsofinstagram"><i class="icofont-instagram"></i></a>
                                    </li>
                                    <li class="d-inline-block" v-if="youtube">
                                        <a :href="splitLinkUrlsofyoutube"><i class="icofont-youtube-play"></i></a>
                                    </li>
                                    <li class="d-inline-block" v-if="blog">
                                        <a :href="splitLinkUrlsofblog"><i class="icofont-blogger"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <ul class="find-us">
                                <li v-if="footerAddress">
                                    <i class="icofont-location-pin"></i>
                                    {{footerAddress}}
                                </li>
                                <li v-if="footerPhoneNo">
                                    <i class="icofont-phone"></i>
                                    <a>
                                        +{{footerPhoneNo}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </footer>

      <section v-if="copyRightText" :style="{ background: copyRightBackgroundColor }" class="copyright-section">
          <div class="bottom-footer">
              <div class="container">
                  <div class="row  align-items-center">
                      <div class="col-lg-12">
                          <div class="copyright-text text-center">
                              <p :style="{ color: footercopyrighttextColor }">&copy;{{ copyRightText }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>



</template>
<script>
    import frontEndService from '@/Services/frontEndService.js';
    export default {
        name: 'FooterVue',
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        components: {
        },
        data() {
            return {
                companyname: localStorage.getItem('companyName'),
                userid: localStorage.getItem('comp-userId'),
                 companylogourl: localStorage.getItem('companyLogo'), companyId: localStorage.getItem('companyId'),
                footerDescription: null, footerLogo: localStorage.getItem('footerLogo'), copyRightText: null, copyRightTextColor: null, footerAddress: '', footerPhoneNo:''
                , serviceList: [], footerServiceUrl: [], footerLinksName: "", footerLinksUrl: "", footerServiceSection: "",
                facebook: '', twitter: '', linkedin: '', instagram: '', workTypeList: null, ShowFooter: false
            }
        },
        computed: {
            
            //splitServiceNames() {
            //    return this.footerServiceSection.split(","); // Splitting the comma-separated string into an array
            //},
            splitServiceNames() {
                return this.footerServices.split(","); // Splitting the comma-separated string into an array
            },
            splitServiceNamesURl() {
                return this.footerServiceUrl.split(",").map(url => {
                    // Check if URL starts with http:// or https://
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },

            splitLinkNames() {
                return this.footerLinksName.split(","); // Splitting the comma-separated string into an array
            },
            splitLinkUrls() {
                return this.footerLinksUrl.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },

            splitCompany_SuportTittlesNames() {
                return this.Company_Suport_LinkNames.split(","); // Splitting the comma-separated URLs string into an array
            },
            splitLinkCompanyUrls() {
                return this.Company_Suport_Urls.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },

            splitLinkUrlsoftwitter() {
                return this.twitter.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofFacebook() {
                return this.facebook.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsoflinkedin() {
                return this.linkedin.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofinstagram() {
                return this.instagram.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofyoutube() {
                return this.youtube.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofblog() {
                return this.blog.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
        },
        created() {
            this.getDynamicContents();
            this.getHeaderWorkTypes();
            this.currentRouteId = this.$route.params.id;
        },
        methods: {
            getHeaderWorkTypes() {
                frontEndService.getAllWorkTypes(this.companyId).then(response => {
                       
                    console.log('Wo_data', response);
                    if (response != null) {
                           
                        this.workTypeList = response.data
                    }
                })
            },
            //SelectedService(id) {
            //    this.currentRouteId = id; // Update currentRouteId to force component reload
            //    this.$router.push({ path: `/cartServices/${id}` });
            //},
            getDynamicContents() {
                   debugger
                frontEndService.getHomepageData(this.companyId, this.userid).then(response => {
                       

                    if (response != null) {
                           
                        this.footerList = JSON.parse(response.data[0].footerdata);
                        console.log("footerdata", JSON.parse(response.data[0].footerdata));

                        this.footerDescription = this.footerList[0].Description || '';
                        this.footerLogo = this.footerList[0].uploadFooterlogo || '';
                        this.copyRightText = this.footerList[0].copyrighttextcolumn || '';
                        this.copyRightBackgroundColor = this.footerList[0].copyrighttextcolor || '';
                        this.footerAddress = this.footerList[0].FooterAddress || '';
                        this.footerPhoneNo = this.footerList[0].PhoneNumber || '';

                        const footerservice = this.footerList[0].CompanyInfo || '';
                        this.footerServiceSection = footerservice.split(',')[0] || '';
                        this.footerServices = this.footerList[0].Companyinfo_LinkNames || '';
                        this.footerServiceUrl = this.footerList[0].CompanyInfo_Url || '';

                        const companyquickview = this.footerList[0].Company_Services || '';
                        this.footerQuickLinks = companyquickview.split(',')[0] || '';
                        this.footerLinksName = this.footerList[0].CompanyService_LinkNames || '';
                        this.footerLinksUrl = this.footerList[0].CompanyService_Url || '';

                        this.footerBackgroundColor = this.footerList[0].Backgroundcolor || '';
                        this.footercopyrighttextColor = this.footerList[0].copyrighttext || '';

                        const companytittle = this.footerList[0].Company_SuportTittles || '';
                        this.Company_SuportTittles = companytittle.split(',')[0] || '';
                        this.Company_Suport_LinkNames = this.footerList[0].Company_Suport_LinkNames || '';
                        this.Company_Suport_Urls = this.footerList[0].Company_Suport_Urls || '';

                        this.ShowFooter = true;

                        this.websiteList = JSON.parse(response.data[0].websiteData);
                        if (this.websiteList && this.websiteList.length > 0) {
                            this.facebook = this.websiteList[0].facebook.split(',')[0];
                            this.twitter = this.websiteList[0].twitter.split(',')[0];
                            this.linkedin = this.websiteList[0].linkedin.split(',')[0];
                            this.instagram = this.websiteList[0].instagram.split(',')[0];
                            this.youtube = this.websiteList[0].Youtube.split(',')[0];
                            this.blog = this.websiteList[0].blog.split(',')[0];
                        }
                        
                        
                    }


                })
            },
        }

    }
</script>

<style scoped>

    .footer-link-items {
        column-count: 2;
        column-gap: 15px;
    }

        .footer-link-items .link-items {
            font-size: 14px;
            padding: 8px 0;
            margin: 0;
        }
    .copyright-section {
        height: 82px;
    }
    @media (max-width:767px) {
        .footer-link-items {
            column-count: 1;
        }
    }
</style>