<template>
    <header class="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu gradient-color-4---" :class="IsHome?' ltn__header-transparent':''">
        <!-- ltn__header-top-area start -->
        <div :class="!IsHome?'ltn__header-top-area section-bg-6 top-area-color-white---':' ltn__header-top-area top-area-color-white'">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="ltn__top-bar-menu">
                            <ul>
                                <li><a href="mailto:info@fraxioned.com?Subject=Flower%20greetings%20to%20you"><i class="icon-mail"></i> info@fraxioned.com</a></li>
                                <li><a href="locations.html"><i class="icon-placeholder"></i> 460 W 50 N 5th Floor, Salt Lake City, UT 84101</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="top-bar-right text-end">
                            <div class="ltn__top-bar-menu">
                                <ul>
                                    <li>
                                        <div class="ltn__social-media">
                                            <SocialMediaIconComponent></SocialMediaIconComponent>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ltn__header-top-area end -->
        <!-- ltn__header-middle-area start -->
        <div :class="(isscrolled)?'ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white sticky-active':'ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black'">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="site-logo-wrap">
                            <div class="site-logo site-logo-main">
                                <router-link :to="{ name: 'HomePage'}"><img :src="ChangeCompanyLogo()" alt="Logo">  </router-link>
                            </div>
                            <div class="get-support clearfix d-none">
                                <div class="get-support-icon">
                                    <i class="icon-call"></i>
                                </div>
                                <div class="get-support-info">
                                    <h6>Get Support</h6>
                                    <h4><a href="tel:+18886686188">1-888-668-6188</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="IsHome?'menu-color-white col header-menu-column':' col header-menu-column'" v-if="!isUserLoggedIn">
                        <div class="header-menu d-none d-xl-block">
                            <nav>
                                <div class="ltn__main-menu">
                                    <ul>
                                        <!--<li class="menu-icon" v-for="link in NavLinks">
        <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu">{{link.displayText}}</router-link>

    </li>-->
                                        <li class="menu-icon dropdown" v-for="(link, index)  in NavLinks" :key="index">
                                            <router-link :class="[JSON.parse(link.childMenu).length >0 ? 'dropdown-toggle' : '','nav-link']" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                            <ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                                <li v-for="childLink in JSON.parse(link.childMenu)">
                                                    <router-link class="nav-link dropdown-item" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="special-link">
                                            <a @click="$emit('openLoginModel')"> Login/Signup </a>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div :class="IsHome?'menu-color-white col header-menu-column':' col header-menu-column'" v-if="isUserLoggedIn">
                        <div class="header-menu d-none d-xl-block">
                            <nav>
                                <div class="ltn__main-menu">

                                    <ul id="nav">
                                        <li class="menu-icon dropdown" v-for="(link, index)  in NavLinks" :key="index">
                                            <router-link :class="[JSON.parse(link.childMenu).length >0 ? 'dropdown-toggle' : '','nav-link']" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                            <ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                                <li v-for="childLink in JSON.parse(link.childMenu)">
                                                    <!--<a class="dropdown-item" :href="childLink.displayText">{childLink.displayText}}</a>-->
                                                    <router-link class="nav-link dropdown-item" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown pr-link show">
                                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#/dashboard" aria-expanded="true">
                                                My Account
                                            </a>
                                            <ul class="dropdown-menu show">
                                                <li>
                                                    <router-link class="login-link" routerlinkactive="active" to="dashboard" ng-reflect-router-link-active="active" href="#/dashboard">Dashboard</router-link>
                                                </li>
                                                <li>
                                                    <a class="login-link" routerlinkactive="active" @click="this.$emit('logout')" ng-reflect-router-link-active="active">Logout</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div class="col--- ltn__header-options ltn__header-options-2 ">
                        <!-- Mobile Menu Button -->
                        <div class="mobile-menu-toggle d-xl-none">
                            <a href="#ltn__utilize-mobile-menu" class="ltn__utilize-toggle" @click="menutoggle">
                                <svg viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L540,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </a>
                        </div>


                        <div id="ltn__utilize-mobile-menu" class="ltn__utilize ltn__utilize-mobile-menu " :class="isTextRed && 'ltn__utilize-open'">
                            <div class="ltn__utilize-menu-inner ltn__scrollbar">
                               
                              
                                <div class="ltn__utilize-menu">
                                    <ul>
                                        <li>
                                            <a href="#">Listings</a>

                                        </li>
                                        <li>
                                            <a href="scheduling.html">Scheduling</a>

                                        </li>
                                        <li>
                                            <a href="Ambassador.html">Ambassador</a>

                                        </li>
                                        <li>
                                            <a href="faq.html">FAQ</a>

                                        </li>
                                        <li>
                                            <a href="Privacy.html">Privacy</a>

                                        </li>
                                        <li><a href="contact.html">Contact</a></li>
                                    </ul>
                                </div>
                               
                                <div class="ltn__social-media-2">
                                    <ul>
                                        <li><a href="#" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" title="Linkedin"><i class="fab fa-linkedin"></i></a></li>
                                        <li><a href="#" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!-- ltn__header-middle-area end -->
    </header>
</template>

<script>
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    import { ref } from 'vue'

    export default {
        name: 'HeaderVue',
        components: {
            SocialMediaIconComponent
        },
        emits: ["openSignUpdmodel", "openLoginModel", "logout"],

        data() {
            const isTextRed = ref(false)
            function menutoggle() {
                isTextRed.value = !isTextRed.value
            }
            return {
                companyLogo: '',
                scrollPosition: null,
                isscrolled: false,
                IsHome: false,
                logoUrl: localStorage.getItem('companyLogo'),
                isTextRed,
                menutoggle,
                curetnRouteName: ''

            }
        },
        props: ['NavLinks', 'isUserLoggedIn'],
        created() {
            this.CheckRoute();
        },
        updated() {
            this.CheckRoute();
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        methods: {
            CheckRoute() {
                this.curetnRouteName = this.$route.name;
                if (this.curetnRouteName != "HomePage") {
                    this.IsHome = false;
                }
                else {
                    this.IsHome = true;
                }
                this.getCompanyLogo();
            },
            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition > 500) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }
                this.getCompanyLogo()
            },
            getCompanyLogo() {
                if (this.curetnRouteName != "HomePage") {
                    if (this.isscrolled) {
                        this.companyLogo = (this.$filters.IsNull(this.logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : this.logoUrl;
                    } else {
                        this.companyLogo = (this.$filters.IsNull(this.logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo-dark.png') : this.logoUrl;
                    }
                }
                else {
                    this.companyLogo = (this.$filters.IsNull(this.logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : this.logoUrl;
                }
            },
            ChangeCompanyLogo() {
                let companyLogo = localStorage.getItem('companyLogo');
                let companySecondaryLogo = localStorage.getItem('companySecondaryLogo');
                if (this.$router.currentRoute.value.name == 'HomePage') {
                    var logo = this.isscrolled ? companySecondaryLogo : companyLogo
                    return logo
                }
                return companySecondaryLogo ? companySecondaryLogo : companyLogo

            }
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        }
    }
</script>