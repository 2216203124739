<template>
    <div class="page-wrapper">
        <router-view />
        <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
        <DialogsWrapper />
    </div>
</template>

<script>
    import MyLayout from '@/layout/layout.vue'
    import AuthUserServices from './Services/AuthUserServices.js';

    export default {
        components: {
            MyLayout
        },
        created() {
           // this.ChangeFavIcon();
            this.GetDynamicContent();
        },
        methods: {
            GetDynamicContent() {
                AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    const favicon = document.getElementById("favicon");
                    favicon.href = res.data.favIconLink;
                })
            },
        }

    }
</script>   

<style>
</style>
