<template>
    <div class="modal fade login-register-form show" tabindex="-1" aria-labelledby="login-register-form" style="display: block; padding-left: 0px;" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" @click="this.$emit('cancel')"><span><i class="fa fa-times"></i></span></button>
                <div class="modal-body">
                    <div class="tab-content">
                        <!-- login-form-tab -->
                        <Form @submit="loginData" :validation-schema="schema" v-slot="{ errors }" autocomplete="off">
                            <div class="popup-heading">Login to your account </div>
                            <div class="login-form-wrap">
                                <div class="form-group">
                                    <div class="form-group-field username-field">
                                        <Field type="text" placeholder="Enter Username" name="username" class="form-control mb-1 no-transform" data-gtm-form-interact-field-id="0"
                                               v-model="form.username" :class="{ 'is-invalid': errors.username }" />
                                        <div class="invalid-feedback">{{errors.username}}</div>
                                    </div><!-- input-group -->
                                </div><!-- form-group -->
                                <div class="form-group">
                                    <div class="form-group-field password-field">
                                        <Field v-bind:type="showconformPassword ? 'text':'password'" placeholder="Enter Password" name="password" v-model="form.password"
                                               data-gtm-form-interact-field-id="1"
                                               class="form-control mb-1"
                                               :class="{ 'is-invalid': errors.password }" />
                                        <span toggle="#password-field" :class="toggleclass" @click="changeclass"></span>
                                        <div class="invalid-feedback">{{errors.password}}</div>
                                    </div><!-- input-group -->
                                </div><!-- form-group -->
                            </div><!-- login-form-wrap -->

                            <div class="form-tools">
                                <div class="d-flex">
                                    <label class="control control--checkbox flex-grow-1">
                                        <input name="remember" type="checkbox">Remember me
                                        <span class="control__indicator"></span>
                                    </label>

                                </div><!-- d-flex -->
                            </div><!-- form-tools -->

                            <button id="houzez-login-btn" type="submit" class="btn btn-primary btn-full-width">
                                Login
                            </button>


                            <div class="login_Register_option">
                                <a class="modal-toggle-2" @click="swtichToRegister">Don't have an account?</a> |
                                <a href="#reset-password-form" @click="this.$emit('switchToForgot')" data-bs-target="#reset-password-form" data-bs-dismiss="modal">Forgot Password?</a>
                            </div>
                        </Form>
                        <!-- tab-content -->

                    </div><!-- modal-body -->
                </div><!-- modal-content -->
                <div class="modal_login_image_wrapper">
                    <div class="modal_login_image"></div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
    import { Form, Field } from 'vee-validate';

    export default {
        components: {
            Form, Field
        },
        props: ['form', 'schema'],
        emits: ["loginData", "cancel", "swtichToRegister", "switchToForgot"],
        data() {
            return {
                showconformPassword: false,
                toggleclass: 'far fa-eye-slash toggle-password'
            }
        },
        methods: {
            loginData() {
                this.$emit("loginData", this.form);
            },
            changeclass() {
                if (this.toggleclass == 'far fa-eye-slash toggle-password') {
                    this.toggleclass = 'far fa-eye toggle-password';
                    this.showconformPassword = true;
                } else {
                    this.toggleclass = 'far fa-eye-slash toggle-password'
                    this.showconformPassword = false;
                }

            },
            swtichToRegister() {
                this.$emit('swtichToRegister')
            }

        }


    }
</script>