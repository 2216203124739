<template>
    <!-- <footer class="ltn__footer-area  ">
        <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-about-widget">
                            <div class="footer-logo">
                                <div class="site-logo site-logo-footer">
                                    <router-link :to="{ path: 'Home'}">  <img :src="companyLogo" alt="Logo"></router-link>
                                </div>
                            </div>
                            <p>{{FooterLogoDescription}} </p>
                            <div class="footer-address">
                                <ul>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-placeholder"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p>{{OurAddress}}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-call"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p><a :href="'tel:'+ OurPhone">{{OurPhone}}</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-mail"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p><a href="mailto:info@fraxioned.com">{{OurEmail}}</a></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-menu-widget clearfix">
                            <h4 class="footer-title">Links</h4>
                            <div class="footer-menu">
                                <ul>
                                    <li v-for="link in footerNavLinks"><router-link :to="{ name: link.routeUrl}">{{link.displayText}}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-menu-widget clearfix">
                            <h4 class="footer-title">Services</h4>
                            <div class="footer-menu">
                                <ul>

                                    <li v-for="serviceLink in serviceLinks"> <router-link :to="{ name: serviceLink.routeUrl}">{{serviceLink.displayText}}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                   


                </div>
            </div>
        </div>
        <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="ltn__copyright-design clearfix">
                            <p v-html="Copyrights"></p>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 align-self-center">
                        <div class="ltn__copyright-menu text-end">
                            <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Claim</a></li>
                                <li><a href="#">Privacy & Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer> -->
    <!-- FOOTER AREA END -->
    <footer class="footer-area">
         <div class="container">
            <div class="row">
               <div class="col-lg-3 col-md-6">
                  <div class="footer-widget">
                     <div class="logo">
                        <a href="index.html">
                        <img src="/src/assets/img/logo-2.png" alt="logo">
                        </a>
                     </div>
                     <p>Ruvena is a technology platform offering a variety of services at home. Customers use our platform to book services such as cleaning, plumbing, carpentry, appliance repair, painting etc. These services are delivered in the comfort of their home and at a time of their choosing. </p>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6">
                  <div class="footer-widget pl-40">
                     <h3>Services</h3>
                     <ul>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">AC & Appliance Repair</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Electricians & Plumber</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Cleaning</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Painting & Wall Panels</a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6">
                  <div class="footer-widget pl-40">
                     <h3>Quick Links</h3>
                     <ul>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Home</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">About Us</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Contact Us</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Privacy Policy</a>
                        </li>
                        <li>
                           <i class="icofont-simple-right"></i>
                           <a href="#">Term and Conditions</a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6">
                  <div class="footer-widget">
                     <h3>Find Us</h3>
                     <div class="footer-social">
                        <ul>
                           <li class="d-inline-block">
                              <a href="#"><i class="icofont-facebook"></i></a>
                           </li>
                           <li class="d-inline-block">
                              <a href="#"><i class="icofont-twitter"></i></a>
                           </li>
                           <li class="d-inline-block">
                              <a href="#"><i class="icofont-linkedin"></i></a>
                           </li>
                           <li class="d-inline-block">
                              <a href="#"><i class="icofont-instagram"></i></a>
                           </li>
                        </ul>
                     </div>
                     <ul class="find-us">
                        <li>
                           <i class="icofont-location-pin"></i>
                           28/A Street, New York City
                        </li>
                        <li>
                           <i class="icofont-phone"></i>
                           <a href="tel: +1010612457 ">
                           +1010612457 
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="bottom-footer">
            <div class="container">
               <div class="row  align-items-center">
                  <div class="col-lg-12">
                     <div class="copyright-text text-center">
                        <p>&copy;2024 Ruvena. All Rights Reserved</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
</template>

<script>
    import newsletter from '@/views/NewsLetter/newsletter.vue'
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        name: 'FooterVue',
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        components: {
            newsletter,
            SocialMediaIconComponent
        },
        data() {
            let currentYear = new Date().getFullYear();

            return {
                currentYear,
                companyLogo: '',
                companyName: ''
            }
        },
        created() {
            this.getCompanyLogo();
            this.companyName = localStorage.getItem('companyName')
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            }
        }

    }
</script>