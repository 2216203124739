import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/layout.vue'
import frontEndService from '../Services/frontEndService.js'
import helpers from '../helpers/filters'


const routes = [
    {
        path: '',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '',
                name: 'HomePageDefault',
                redirect: to => {
                    return { path: `/Home` }
                }
            },
            {
                path: 'Home',
                name: 'HomePage',
                component: () => import('@/views/Home/HomePage.vue')
            },

            {
                path: 'myprofile',
                name: 'MyProfile',
                component: () => import('@/views/MyProfile/Profile.vue')
            },
            {
                path: 'mobileDashboard',
                name: 'mobileDashboard',
                component: () => import('@/views/MobileDashboard/mobileDashboard.vue')
            },
            {
                path: 'Aboutus',
                name: 'Aboutus',
              //  component: () => import('@/views/dynamicPages/dynamicPages.vue')

                component: () => import('@/layout/Themes/DefaultTheme/Views/DynamicPages/dynamicPages.vue')
            },
            //{
            //    path: ':url',
            //    name: 'dynamicPage',
            //    component: () => import('@/views/dynamicPages/dynamicPages.vue')
            //},
            {
                path: 'Howitworks',
                name: 'Howitworks',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'AssetLegalTerms/:Id',
                name: 'AssetLegalTerms',
                component: () => import('@/views/AssetLegalTerms/AssetLegalTerms.vue')
            },
            {
                path: 'PrivacyPolicy',
                name: 'PrivacyPolicy',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'TermsandConditions',
                name: 'TermsandConditions',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'Scheduling',
                name: 'Scheduling',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'Listings',
                name: 'Listings',
                component: () => import('@/views/ManageAsset/assetListing.vue')
            },
            {
                path: 'AssetDetail/:id',
                name: 'AssetDetail',
                component: () => import('@/views/ManageAsset/assetDetail.vue')
            },
            {
                path: 'AssetDetail',
                name: 'AssetDetails',
                component: () => import('@/views/ManageAsset/assetDetail.vue')
            },
            {
                path: 'sell',
                name: 'sell',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'ambassador',
                name: 'ambassador',
                component: () => import('@/views/ManageMembership/membershipList.vue')
            },
            {
                path: 'membershipdetail/:id',
                name: 'membershipdetail',
                component: () => import('@/views/ManageMembership/membershipdetail.vue')
            },
            {
                path: 'thankyou',
                name: 'thankyou',
                component: () => import('@/views/ThankYou/thankyou.vue')
            },
            {
                path: 'contactus',
                name: 'ContactUs',
               // component: () => import('@/views/contactus/contactus')

                component: () => import('@/layout/Themes/DefaultTheme/Views/contactus/contactus.vue')
            },
            {
                path: 'pressnews',
                name: 'pressnews',
                component: () => import('@/views/News/news.vue')
            },
            {
                path: 'pressnewsdetail/:id',
                name: 'Press News Detail',
                component: () => import('@/views/News/newsdetail.vue')
            },
            {
                path: 'unsubscribenewsletter/:email',
                name: 'Unsubscribe Newsletter',
                component: () => import('@/views/shared/UnsubscribeNewsletter/unsubscribenewsletter.vue')
            },
            {
                path: 'unsubscribe/:email/:companyId',
                name: 'Unsubscribe',
                component: () => import('@/views/Unsubscribe/unsubscribe.vue')
            },
            {
                path: 'resetpassword',
                name: 'Set Password',
                component: () => import('@/views/SetPassword/setPassword.vue')
            },

            {
                path: 'assetcomparedetails',
                name: 'assetcomparedetails',
                component: () => import('@/views/ManageAsset/assetCompareDetails.vue')
            },
            {
                path: 'reviewslisting',
                name: 'ReviewsListing',
                component: () => import('@/views/ManageReviews/reviewsListing.vue')
            },
            {
                path: 'FAQ',
                name: 'FAQ',
                component: () => import('@/views/FrequentlyAskedQuestions/frequentlyAskedQuestionsAnswer.vue')
            },
            {
                path: 'clientRegistration',
                name: 'ClientRegistration',
                component: () => import('@/views/Registration/clientRegistration.vue')
            },
            {
                path: 'SignupPage',
                name: 'SignupPage',
                component: () => import('@/views/SignUpPage/SignupPage.vue')
            },
            {
                path: 'ForgotPassword',
                name: 'ForgotPassword',
                component: () => import('@/views/ForgotPassword/ForgotPassword.vue')
            },
            {
                path: 'Login',
                name: 'Login',
                component: () => import('@/views/Login/login.vue')
            },
            {
                path: 'Testimonials',
                name: 'Testimonials',
                component: () => import('@/views/Testimonial/testimonialList.vue')
            },
            {
                path: 'Blog',
                name: 'Blog',
                component: () => import('@/views/Blog/blogList.vue')
            },
            {
                path: 'blogdetail/:id',
                name: 'Blog Detail',
                component: () => import('@/views/Blog/blogDetail.vue')
            },
            {
                path: 'VeteransVacation',
                name: 'VeteransVacation',
                component: () => import('@/views/VeteransVacations/veteransvacations.vue')
            },
            {
                path: 'AssetConfirm',
                name: 'Asset Confirm',
                component: () => import('@/views/AssetBuyConfirmation/Thankyou.vue')
            },
            {
                path: 'AssetRentConfirm',
                name: 'Asset Rent Confirm',
                component: () => import('@/views/AssetRentConfirmation/Thankyou.vue')
            },
            {
                path: 'iconic',
                name: 'Iconic Page',
                component: () => import('@/views/Iconic/iconic.vue')
            },
            {
                path: 'LandingPageThankYou',
                name: 'Landing Page Thank You',
                component: () => import('@/views/LandingPageThankYou/landingpagethankyou.vue')
            },
            {
                path: 'FrexiiCRM',
                name: 'FrexiiCRM',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            //10311Exchange
            {
                path: '10311Exchange',
                name: '10311Exchange',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },

            {
                path: 'services',
                name: 'AllServices',
                component: () => import('@/views/AllServices/allServices.vue')
            },
            {
                path: 'cart',
                name: 'Cart',
               
                /*component: () => import('@/views/CartService/addCart.vue')*/
                component: () => import('@/layout/Themes/DefaultTheme/Views/CartService/addCart.vue')
            },
            {
                path: 'cartServices/:id',
                name: 'Cart',

                component: () => import('@/layout/Themes/DefaultTheme/Views/CartService/addCart.vue')
            },
            {
                path: 'checkout/:id',
                name: 'Checkout',

                component: () => import('@/views/CheckoutPage/checkout.vue')
            },


        ]
    },
    {
        path: '/logoutRedirect/:origin',
        name: 'logoutRedirect',
        component: () => import('../layout/LogoutRedirect.vue')
    },
    {
        path: '/errorPage',
        name: 'errorPage',
        component: () => import('@/views/ErrorPage/errorPage.vue')
    }
];

//Global Arrays For Dynamic Menu
let HeaderNavlinks = []
let FooterNavlinks = []
const router = createRouter({
    history: createWebHistory(),
    routes, HeaderNavlinks, FooterNavlinks
})


function createMenuItems(menuList) {
    return menuList.map(item => {
        const matchingRoute = routes[0].children.find(route => route.name === item.routeUrl);
        return {
            routeUrl: matchingRoute ? item.routeUrl : 'dynamicPage',
            displayText: item.displayText,
            childMenu: item.ChildMenu,
            params: matchingRoute ? null : { url: item.routeUrl }
        };
    });
}

//async function setMenuItems(userId, companyId) {
//    const response = await frontEndService.GetHeaderFooterData(userId, companyId);
//    const HeaderFooterList = JSON.parse(Object.values(response.data));

//    HeaderNavlinks.push(JSON.stringify(createMenuItems(HeaderFooterList.HeaderMenuListing)));
//    FooterNavlinks.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing)));
//}

router.beforeEach(async (to, from, next) => {
   debugger
    const specialRoutes = ['errorPage', 'logoutRedirect'];
    if (specialRoutes.includes(to.name)) {
        return next();
    }
    let companyDomainName = location.host.split('.')[0];
    let companyUniqueName = location.host.split('.').shift();

    try {
        if (helpers.IsNull(companyDomainName, true, null) == null) {
            throw new Error('Invalid company data');
        }
        if (to.matched.length == 0) {
           // throw new Error('Invalid Page');
        }
        const companyResponse = await frontEndService.GetCompanyIdsandUserIdByDomainName(companyDomainName);
        // if (companyDetailsResponse.data.statusCode !== 200) {
        //     throw new Error('Company details not found');
        // }
        if (companyResponse.status !== 200) {
            throw new Error('Company  not found');
        }
        const companyData = companyResponse.data.result ;
        await SetData(companyData, to.name);

        
        /*await setMenuItems(companyData.userId, companyData.companyId);*/

        await beforeRouteHitMethods(to);
        if (to.hash == '' || to.hash == null) {
            window.scrollTo(0, 0);
        }
        await GetmetaData(to);
        return next();
        
    }
    catch (error) {
        console.error("dfffd",error);
        return next('/errorPage');
    }
})

async function SetData(companyData, title, companyUniqueName) {
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('comp-userId');
    localStorage.removeItem('companyURL');
    localStorage.removeItem('companyLogo');
    localStorage.removeItem('faviconUrl');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('footerLogo');
    
    localStorage.setItem('companyId', companyData.companyId);
    localStorage.setItem('companyName', companyData.companyName);
    localStorage.setItem('faviconUrl', companyData.faviconUrl);
    localStorage.setItem('comp-userId', companyData.userId);
    localStorage.setItem('footerLogo', companyData.footerLogo);
    localStorage.setItem('companyURL', companyUniqueName);
    localStorage.setItem('companyLogo', companyData.logoUrl ? companyData.logoUrl : companyData.logoUrl);

    let AspUserId = localStorage.getItem('loginAspUserId')

    if (companyData.userId != null && companyData.companyId != null) {
        localStorage.setItem('isLogin', false);
    }
    if (AspUserId != null || AspUserId != undefined) {
        localStorage.setItem('isLogin', true);
    }
    

    //Check for login in user belong to current company or not 
    if (localStorage.getItem('userinfo') != null && localStorage.getItem('userinfo') != undefined) {
        let userDataJSON = JSON.parse(localStorage.getItem('userinfo'));
        localStorage.setItem('isLogin',true);
        let companyId = userDataJSON.data ? userDataJSON.data.companyId : userDataJSON.companyId;
        if (companyId != localStorage.getItem('companyId').toLowerCase()) {
            localStorage.removeItem("comp-userId");
        }
    }

    if (title == 'undefinded' || typeof title == "undefined") {
        title = ''
    }
    document.title = `${companyData.companyName} | ` + `${title}`;

    const urlfavicon = companyData.faviconUrl;

    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png'; // Ensure the correct type
    link.rel = 'icon';
    link.href = urlfavicon;
    document.getElementsByTagName('head')[0].appendChild(link);

    return true


}

// this fuction will check for side compare bar close fucntionality 
async function beforeRouteHitMethods(to) {
    var compareElement = document.getElementById('comparisonblock');
    if (to.name.indexOf('HomePage') == 0 || to.name.indexOf('Listings') == 0) {
    }
    else {
        if (compareElement != null) {
            compareElement.classList.remove('open');
        }
    }
    return
}

async function GetmetaData(to) {
    let metaModel = {
        PageTitle: to.name,
        PageUrl: to.href.replace('/', ''),
        companyId: localStorage.getItem('companyId')
    }
    const response = await frontEndService.GetdynamicMetadata(metaModel);
    document.getElementById('keyword').setAttribute("content", response.data.MetaKeyword)
    document.getElementById('description').setAttribute("content", response.data.MetaDescription)
    document.getElementById('title').setAttribute("content", response.data.MetaAuthor)
    var screen = window.outerWidth;
    if (screen > 1080) {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1")
    } else {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1, user-scalable='no'")
    }
}


export default router