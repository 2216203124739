<template>
    <section id="footer_newsltter" class="mt-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h3>Newsletter</h3>
                    <p>Subscribe to our newsletter and stay up to date on the latest available properties.</p>
                </div>
                <div class="col-md-6 center-control">
                    <Form autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}" class="row newsletrform pt-5 justify-content-end">
                        <div class="col-sm-9 col-md-7 pe-md-0">
                            <Field type="text" name="emailname" v-model="emailValuecomp"
                                   :class="{ 'form-control' : true ,'is-invalid': errors.emailname }"
                                   placeholder="Enter Email" />
                            <div class="invalid-feedback">{{errors.emailname}}</div>
                        </div>

                        <div class="col-sm-3 col-md-3 ps-md-0">
                            <button type="submit" class="btn btn-primary">Subscribe</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    export default {
        components: {
            Form, Field
        },
        props: ['schema'],
        emits: ["submitNewsLetter"],
        data() {
            return {
                emailValuecomp:''
                }
        },
        methods: {
            onSubmit() {
                this.$emit("submitNewsLetter", this.emailValuecomp);
            }
        }
    }
</script>

<style>
    @media (max-width:768px){
        .invalid-feedback{
            text-align:left;
            margin-top:-5px;
        }
    }
</style>