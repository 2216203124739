import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import "bootstrap/dist/css/bootstrap.min.css"
import '@/assets/Icons/icons.css'

import 'vue-loading-overlay/dist/css/index.css'
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons 

import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Button from "primevue/button";

import axios from 'axios';
import setupInterceptors from '@/Services/setupInterceptors.js';
import FilterUtility from '@/helpers/filters.js'
import HelpersUtility from '@/helpers/helpers.js'
import configuration from '@/helpers/configuration.js'
import Swiper from './assets/vendor/swiper/SwiperMain'
import bootstrap from './assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
import script from './assets/js/main.js'
import { LoadingPlugin } from 'vue-loading-overlay';
import router from './router'
import * as ConfirmDialog from "vuejs-confirm-dialog";
import Loading from '@/views/shared/loader.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import { vMaska } from "maska"
import VueHtmlToPaper from "./views/shared/VueHtmlToPaper";
import CKEditor from '@mayasabha/ckeditor4-vue3';
import Dropdown from "primevue/dropdown";
import GoogleMap from "@/views/shared/google-map/GoogleMap.vue"
import VueLazyLoad from 'vue3-lazyload'
const app = createApp(App)
const pinia = createPinia()
// Package used 
app.use(router).use(LoadingPlugin)
app.use(setupInterceptors)
app.use(ConfirmDialog)
app.use(PrimeVue);
app.use(ToastService);
app.use(VueHtmlToPaper);
app.use(pinia)
app.component("Toast", Toast);
app.component("Button", Button);
app.component('Loading', Loading);
app.component('DataTable', DataTable);
app.component('Paginator', Paginator);
app.component('Column', Column);
app.component('Dropdown', Dropdown)
app.directive('maska', vMaska)
app.component('GoogleMap', GoogleMap);
app.use(VueLazyLoad, {
    // options...
})

//Global Properties 
app.config.globalProperties.$filters = FilterUtility;
app.config.globalProperties.$helpers = HelpersUtility;
app.config.globalProperties.$custConfig = configuration;
window.$ = window.jQuery = require('jquery');

//Services
app.provide('axios', axios)

//Add Mixin
app.mixin("", script);
app.mixin("", Swiper);
app.mixin("", bootstrap);
app.mixin({
    methods: {
        success: function (msg) {
            this.$toast.add({ severity: 'success', summary: "Success Message", detail: msg, life: 3000 });
        },
        error: function (msg) {
            this.$toast.add({
                severity: 'error', summary: "Error Message", detail: msg, life: 3000
            });
        },
        warn: function (msg) {
            this.$toast.add({
                severity: 'warn', summary: "Warning Message", detail: msg, life: 3000
            });
        }
    },
})

app.use(CKEditor);
app.use(function (req, res, next) {
    const isProduction = process.env.VUE_APP_PRODUCTION;
    if (isProduction.toLocaleLowerCase() == "true") {
        if (location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }
});
app.mount('#app')
