<template>
    <SubscribeNewsletter :schema="schema"
                         :formdata="formdata"
                         :showsubscribenewsletter="showsubscribenewsletter"
                         @submitdata="submitdata" :isCapchaValid="isCapchaValid"
                         @cancel="cancel">

    </SubscribeNewsletter>
    <Loading v-model:active="isLoading"></Loading>
</template>

<script>
    import Service from '@/Services/frontEndService.js';
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    import axios from 'axios';
    export default {
        name: 'Company Login',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.SubscribeNewsletter = require('@/layout/Themes/DarkTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.SubscribeNewsletter = require('@/layout/Themes/CleanTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            }
            else if (this.themeName == 'Classic') {

                this.$options.components.SubscribeNewsletter = require('@/layout/Themes/ClassicTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            }
            else if (this.themeName == 'Modern') {

                this.$options.components.SubscribeNewsletter = require('@/layout/Themes/ModernTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            }
            else {
                this.$options.components.SubscribeNewsletter = require('@/layout/Themes/DefaultTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            }
        },
        components: {
            SubscribeNewsletter: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/SubscribeNewsletter/subscribeNewsletter.vue');
                }
            },
            Form,
            Field,

        },
        data() {

            const schema = Yup.object().shape({
                name: Yup.string()
                    .required('Name is required'),
                email: Yup.string()
                    .required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
                phone: Yup.string()
                    .required('Phone is required'),

            });
            return {
                isLoading: false, isCapchaValid: true,
                OpenForgotPassword: false,
                assignedToFrexiCRM: 'sPb8eNFK8UrBrIow1Vlo',
                schema,
                formdata: {
                    name: "",
                    email: "",
                    phone: ""
                },

            };
        },
        props: ["closenewslettermodel", "permanentclose"],
        methods: {
            submitdata() {
                let object = JSON.stringify({ name: this.formdata.name, Email: this.formdata.email })
                document.cookie = "subscribed=" + object + ";max-age=31536000";
                let formata = {
                    id: null,
                    name: this.formdata.name,
                    email: this.formdata.email,
                    phone: this.formdata.phone,
                    userId: localStorage.getItem("userId"),
                    companyId: localStorage.getItem("companyId"),
                    Domain: "https://" + window.location.host
                }
                if (grecaptcha.getResponse().length === 0 || grecaptcha.getResponse() == "") {
                    //this.error('Please verify you are not a Robot');
                    this.isCapchaValid = false;
                    return
                }
                else {
                    this.isCapchaValid = true;
                }
                this.isLoading = true;
                var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IlprOTlSU25CWnZkdTQxRkNCMzBUIiwiY29tcGFueV9pZCI6IkN1QmxzdlU1OXdDTEVyZzBDNzdDIiwidmVyc2lvbiI6MSwiaWF0IjoxNjg1NjQxNTU0NzY3LCJzdWIiOiJ1c2VyX2lkIn0.oBN3BCiT2jsrXw9NPyqtbzmxhS4BmuPlIkMssMlvpLg'
                Service.companysubscribeNewsletter(formata).then(res => {
                    if (res.data == 1) {
                        this.success("Subscribe to Our Newsletter Successfully")
                        if (res.data == 1 || res.data =="1") {
                            if (formata.companyId.toUpperCase() == "E42A359A-D517-483C-8D7D-5353CA886B41") {
                                axios.post(`https://rest.gohighlevel.com/v1/contacts/`, {
                                    email: formata.email,
                                    phone: formata.phone,
                                    firstName: formata.name,
                                    lastName: "",
                                    source: "website",
                                    name: formata.name,
                                    customField: {
                                        //"LiVa7S5aL6evIOkSQHxB": "Newsletter",
                                        //"tpuNZUxxqzZdAGQJPohv": "Newsletter",
                                        //"ns9qQKCtk1C6DtvAv2j5": "Newsletter"
                                        "l4oPSum4nUoGsXSQkoU9": "Newsletter",
                                        "eLSrsHkS1nB0G4G0prBt": "" + " Submission URL: " + location.href,
                                        "ns9qQKCtk1C6DtvAv2j5": "Newsletter",
                                    },
                                },
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    }).then(res => {
                                        console.log('lead Res', res)
                                        axios.post(`https://rest.gohighlevel.com/v1/pipelines/9IK1J4qsCV3yegFk6KRz/opportunities/`, {
                                            title: "Newsletter",
                                            status: 'open',
                                            stageId: '8e68c9b6-a4a0-44bb-8b5a-d2b8a5b491d5',
                                            email: formata.email,
                                            phone: formata.phone,
                                            firstName: formata.name,                                             
                                            name: formata.name,
                                            assignedTo: this.assignedToFrexiCRM,
                                            source: 'website',
                                            contactId: res.data.contact.id

                                        },
                                            {
                                                headers: {
                                                    'Authorization': `Bearer ${token}`
                                                }
                                            }).then(res => {

                                                this.isLoading = false;
                                                console.log('oper Res', res)
                                            }).catch(error => {
                                                this.isLoading = false;

                                            });
                                    }).catch(error => {
                                        this.isLoading = false;

                                    });
                            }

                            else {

                                this.isLoading = false;
                            }



                        }

                        this.$emit('permanentclose');
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.error('Something went wrong');
                    }

                })
            },
            cancel() {
                this.$emit('closenewslettermodel');
            }
        }
    }
</script>