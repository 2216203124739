// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/Flaticon.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../fonts/Flaticond41d.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../fonts/Flaticon.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../fonts/Flaticon.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../fonts/Flaticon.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../fonts/Flaticon.html", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "#Flaticon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:flaticon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\");font-weight:400;font-style:normal}@media screen and (-webkit-min-device-pixel-ratio:0){@font-face{font-family:flaticon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\")}}[class*=\" flaticon-\"]:after,[class*=\" flaticon-\"]:before,[class^=flaticon-]:after,[class^=flaticon-]:before{font-family:Flaticon;font-style:normal}.flaticon-medal:before{content:\"\\f100\"}.flaticon-credit-card:before{content:\"\\f101\"}.flaticon-icon-1584892:before{content:\"\\f102\"}.flaticon-spray:before{content:\"\\f103\"}.flaticon-clean-house:before{content:\"\\f104\"}.flaticon-wiper:before{content:\"\\f105\"}.flaticon-tap:before{content:\"\\f106\"}.flaticon-planet-earth:before{content:\"\\f107\"}.flaticon-clean:before{content:\"\\f108\"}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
