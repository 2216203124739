<template>

    <loginComponent v-if="!OpenForgotPassword" :schema="schema"
                    :form="form"
                    @loginData="loginData"
                    @swtichToRegister="swtichToRegister"
                    @switchToForgot="switchToForgot"
                    @cancel="cancel">

    </loginComponent>

    <ForgotPasswordComponent v-if="OpenForgotPassword" :ForgotPswSchema="ForgotPswSchema"
                             :ForgotPasswordform="ForgotPasswordform"
                             @submitForgotPass="submitForgotPass"
                             @openloginpopup="OpenForgotPassword=false"
                             @cancelForgotModel="cancelForgotModel">
    </ForgotPasswordComponent>

    <Loading v-model:active="isLoading"></Loading>
</template>
<script>
    import UserService from '@/Services/frontEndService.js';
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';

    export default {
        name: 'Company Login',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.loginComponent = require('@/layout/Themes/DarkTheme/Views/LoginPage/loginpage.vue').default;
                this.$options.components.ForgotPasswordComponent = require('@/layout/Themes/DarkTheme/Views/ForgotPassword/ForgotPasswordModel.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.loginComponent = require('@/layout/Themes/CleanTheme/Views/LoginPage/loginpage.vue').default;
                this.$options.components.ForgotPasswordComponent = require('@/layout/Themes/CleanTheme/Views/ForgotPassword/ForgotPasswordModel.vue').default;
            }
            else if (this.themeName == 'Classic') {

                this.$options.components.loginComponent = require('@/layout/Themes/ClassicTheme/Views/LoginPage/loginpage.vue').default;
                this.$options.components.ForgotPasswordComponent = require('@/layout/Themes/ClassicTheme/Views/ForgotPassword/ForgotPasswordModel.vue').default;
            }
            else if (this.themeName == 'Modern') {

                this.$options.components.loginComponent = require('@/layout/Themes/ModernTheme/Views/LoginPage/loginpage.vue').default;
                this.$options.components.ForgotPasswordComponent = require('@/layout/Themes/ModernTheme/Views/ForgotPassword/ForgotPasswordModel.vue').default;
            }
            else {
                this.$options.components.loginComponent = require('@/layout/Themes/DefaultTheme/Views/LoginPage/loginpage.vue').default;
                this.$options.components.ForgotPasswordComponent = require('@/layout/Themes/DefaultTheme/Views/ForgotPassword/ForgotPasswordModel.vue').default;
            }
        },
        components: {
            loginComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/LoginPage/loginpage.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/LoginPage/loginpage.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/contactus/contactus.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/LoginPage/loginpage.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/LoginPage/loginpage.vue');
                }
            },
            ForgotPasswordComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/ForgotPassword/ForgotPasswordModel.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/ForgotPassword/ForgotPasswordModel.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/ForgotPassword/ForgotPasswordModel.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/ForgotPassword/ForgotPasswordModel.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/ForgotPassword/ForgotPasswordModel.vue');
                }
            },
            Form,
            Field,

        },
        emits: ["cancelmodel"],
        props: ['AfterLoginRoute'],
        data() {
            const ForgotPswSchema = Yup.object().shape({
                Username: Yup.string()
                    .required('Username is required'),
            });
            const schema = Yup.object().shape({
                username: Yup.string()
                    .required('Username is required'),
                password: Yup.string()
                    .required('Password is required'),

            });
            return {
                isLoading: false,
                OpenForgotPassword: false,
                schema, ForgotPswSchema,
                form: {
                    username: "",
                    password: "",
                    isRemember: false
                },
                ForgotPasswordform: {
                    Username: '',
                    CompanyName: localStorage.getItem('companyName'),
                    logolink: localStorage.getItem('companyLogo'),
                    companyUrl: localStorage.getItem('companyURL')
                }

            };
        },

        methods: {

            async loginData() {
                   
                this.isLoading = true;
                if (this.form.isRemember) {
                    localStorage.username = this.form.username;
                    localStorage.password = this.form.password;
                    localStorage.isRemember = this.form.isRemember;

                }
                else {
                    localStorage.removeItem("username");
                    localStorage.removeItem("password");
                    localStorage.removeItem("isRemember");
                }

                const User = new FormData();
                User.append("username", this.form.username);
                User.append("password", this.form.password);
                User.append("browser", "Chrome 108.0.0.0");
                User.append("companyUrl", "");
                User.append("os", "windows-10");
                User.append("rememberMe", "false");
                User.append("companyId", localStorage.getItem("comp-companyId"));
                let info;
                try {
                    await UserService.LogIn(User).then(response => (
                        info = response.data

                    ))
                    //    
                    if (info.status == 200) {
                           
                        localStorage.setItem("Comp-Login-access_token", info.token);
                        localStorage.setItem("Comp-LoginUserId", info.userId);
                        await UserService.getCurrentUserDetail(info.userId, '00Test');
                        this.$parent.isUserLoggedIn = true;
                        this.$emit("cancelmodel");

                        if (this.AfterLoginRoute == 'Home') {
                               
                            this.$router.push({ name: `UserDashBorad` });
                        }
                        else {
                            this.$router.go(0);
                        }
                        this.success('Login Successfully');

                    }

                    else if (info.status == 201) {//deleted/inactive user
                        this.isLoading = false;
                        this.error(info.token);
                    }

                    else if (info.status == 501) {
                        this.isLoading = false;
                        this.error("Your Account is Blocked for 30 minutes because you have entered wrong username/password");
                    }
                    else {
                        this.isLoading = false;
                        this.error("Invalid username/password!");
                    }

                    this.showError = false
                } catch (error) {
                    this.showError = true
                }
            },
            cancel() {
                this.$emit("cancelmodel");
            },
            swtichToRegister() {
                this.$router.push({name:'SignupPage'});
                this.$parent.loginmodelopen = false;
                this.$helpers.disablePopUpCss();
                //this.$parent.isopenSignUpModal = true;

                
            },
            switchToForgot() {
                this.OpenForgotPassword = true;
                this.$parent.loginmodelopen = true;
            },

            submitForgotPass() {
                this.isLoading = true;
                UserService.forgotpassword(this.ForgotPasswordform).then(response => {
                    let info = response.data;
                    if (info.statusCode == 200) {
                        this.$parent.loginmodelopen = false;
                        this.$emit('cancelmodel');
                        this.success(info.responseMessage);
                        this.isLoading = false;
                    }
                    else {
                        this.error(info.responseMessage);
                        this.isLoading = false;
                    }
                })

            },
            cancelForgotModel() {
                this.OpenForgotPassword = false;
                this.$emit('cancelmodel')

            }

        }

    }
</script>